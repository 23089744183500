<template>
  <div>
    <b-card v-if='!isDataLoaded'>
      <div class='text-center text-success my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>

    <div v-else>
      <b-card>
        <h3 class='mb-2'>
          {{ $t('Personal information') }}
        </h3>
        <b-row>
          <b-col
            v-for='(item,key,index) in personal_information'
            :key='index'
            cols='12'
            md='6'
            lg='4'
          >
            <h4 class='custom-line-clamp-one'>
              {{ $t(key) }}
            </h4>
            <p>
              {{ item }}
            </p>
            <hr>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h3 class='mb-2'>
          {{ $t('property_information') }}
        </h3>
        <b-row>
          <b-col
            v-for='(item,key,index) in property_information'
            :key='index'
            cols='12'
            md='6'
            lg='4'
          >
            <h4 class='custom-line-clamp-one'>
              {{ $t(key) }}
            </h4>
            <p>
              {{ item }}
            </p>
            <hr>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h3 class='mb-2'>
          {{ $t('The geographical location of the property') }}
        </h3>
        <b-row>
          <b-col
            v-for='(item,key,index) in geographical_location'
            :key='index'
            cols='12'
            md='6'
            lg='4'
          >
            <h4 class='custom-line-clamp-one'>
              {{ $t(key) }}
            </h4>
            <p>
              {{ item }}
            </p>
            <hr>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if='guess_document.appraisal_copy_file'>
        <h3 class='mb-2'>
          {{ $t('Guess document') }}
        </h3>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant='outline-primary'
              type='link'
              download
              target='_blank'
              :href='guess_document.appraisal_copy_file'
            >
              <feather-icon
                icon='FileTextIcon'
                class='mr-50'
              />
              <span class='align-middle'>{{ $t('Download guess document') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if='images'>
        <h3 class='mb-2'>
          {{ $t('images') }}
        </h3>
        <div
          class='real-estate-images'
        >
          <div
            v-for='(image,index) in images'
            :key="'image_'+index"
            class='box-img'
          >
            <img
              :src='image.original_url'
              alt='Image'
              class='w-100 custom-object-fit'
            >
          </div>
        </div>
      </b-card>
    </div>

  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      details: null,
      images: [],
      personal_information: {},
      property_information: {},
      geographical_location: {},
      guess_document: {},
      isDataLoaded: false,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate-sell-requests/${this.$route.params.resourceId}`)
        .then(res => {
          const detailsObj = res.data.data
          this.images = detailsObj.images
          this.personal_information = _.pick(detailsObj, [
            'customer_name',
            'contact_number',
            'preferred_time_to_call_value',
          ])
          this.property_information = _.pick(detailsObj, [
            'real_state_type',
            'expected_selling_price',
            'details',
          ])
          this.geographical_location = _.pick(detailsObj, [
            'city',
            'district',
            'street',
          ])

          this.guess_document = _.pick(detailsObj, [
            'appraisal_copy_file',
          ])
          this.isDataLoaded = true
        })
    } else {
      this.isDataLoaded = true
    }
  },
}
</script>
